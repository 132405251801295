<template>
    <AdminToast></AdminToast>
    <div class='grid'>
        <EmployeeListReport :report='employeeListReport'></EmployeeListReport>
        <div class='col-12 md:col-6 lg:col-12'>
            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>
                    <div class='col-12 lg:col-8'>
                        <h5 class='mb-0 mt-3'><i class='pi pi-check mr-2'></i> Proje Personel Listesi</h5>
                    </div>

                    <div class='col-12 lg:col-4 text-right'>
                        <a href='#/ProjectEmployee/Add'>
                            <Button label='Proje Personel Ekle' icon='pi pi-plus' class='p-button-danger mr-2'></Button>
                        </a>

                        <a href='#/ProjectEmployee/Calculate'>
                            <Button label='Maaş Hesapla' icon='pi pi-money-bill' class='p-button  mr-2'></Button>
                        </a>

                        <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success' @click='exportExcel'></Button>
                    </div>
                </div>

                <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='20' dataKey='id' :rowHover='true' v-model:filters='filters' filterDisplay='menu' :loading='loadingIndicator' :filters='filters' responsiveLayout='scroll' :globalFilterFields="['employee.name','employee.surName','employee.identityNumber','employee.gsm','employee.email','bankName','iban','department','employee.gender']">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <Button type='button' icon='pi pi-filter-slash' label='Temizle' class='p-button-outlined mb-2' @click='clearFilter()' />
                            <span class='p-input-icon-left mb-2'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Veri bulunamadı.
                    </template>

                    <template #loading>
                        Proje Personel listesi yükleniyor. Lütfen bekleyin.
                    </template>

                    <Column header='Profil' filterField='name' :showFilterMatchModes='false' bodyClass='text-center'>
                        <template #body='{data}'>
                            <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                        </template>
                    </Column>

                    <Column field='employee.name' header='Ad' filterField='employee.name' :showFilterMatchModes='false'>
                        <template #body='{data}'>
                            <a @click='edit(data)' class="cursor-pointer">
                                {{ data.employee.name }}
                            </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' header='Soyad' filterField='employee.surName' :showFilterMatchModes='false'>
                        <template #body='{data}'>
                            <a @click='edit(data)' class="cursor-pointer">
                                {{ data.employee.surName }}
                            </a>
                        </template>
                    </Column>

                    <Column field='employee.identityNumber' header='T.C. Kimlik' filterField='employee.identityNumber' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column header='Doğum Tarihi' filterField='employee.birthday' field='employee.birthday' dataType='date' :sortable='false'>
                        <template #body='{data}'>
                            {{ formatDate(data.employee.birthday) }}
                        </template>
                    </Column>

                    <Column header='Cinsiyet' field='gender' dataType='gender' :sortable='false' style='width:7rem'>
                        <template #body='{data}'>
                            {{ data.employee.gender }}
                        </template>
                    </Column>

                    <Column field='employee.gsm' header='Mesaj' filterField='employee.gsm' :showFilterMatchModes='false' bodyClass='text-center'>
                        <template #body='{data}'>
                            <a :href="'https://wa.me/+90' + data.employee.gsm" v-if='data.employee.gsm != null && data.employee.gsm != ""' target='_blank'>
                                <Button icon='pi pi-whatsapp' v-if='data.employee.gsm != null && data.employee.gsm != ""' class='p-button-rounded p-button-success' />
                            </a>
                        </template>
                    </Column>

                    <Column field='employee.gsm' header='GSM' filterField='employee.gsm' :showFilterMatchModes='false' style='width:8rem'>
                        <template #body='{data}'>
                            <a :href="'tel:+90' + data.employee.gsm" v-if='data.employee.gsm != null && data.employee.gsm != ""'>{{ data.employee.gsm }}</a>
                        </template>
                    </Column>

                    <Column field='employee.email' header='E-Posta' filterField='email' :showFilterMatchModes='false' style='width:12rem' class='hidden'>
                        <template #body='{data}'>
                            <a :href="'mailto:' + data.employee.email" v-if='data.employee.email != null && data.employee.email != ""'>{{ data.employee.email }}</a>
                        </template>
                    </Column>

                    <Column header='Departman' field='department' dataType='department' :sortable='false' style='width:7rem'>
                        <template #body='{data}'>
                            {{ data.department }}
                        </template>
                    </Column>

                    <Column header='Maaş' filterField='officialSalary' field='officialSalary' :sortable='true' style='width:10rem'>
                        <template #body='slotProps'>
                            <b class='text-green-600'>{{ formatPrice(slotProps.data.officialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='Diğer Maaş' filterField='unOfficialSalary' field='unOfficialSalary' :sortable='true' style='width:10rem'>
                        <template #body='slotProps'>
                            <b class='text-red-600'>{{ formatPrice(slotProps.data.unOfficialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='Toplam Maaş' filterField='startDate' style='width:10rem'>
                        <template #body='{data}'>
                            <b>{{ formatPrice(data.unOfficialSalary + data.officialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='IBAN' filterField='iban'>
                        <template #body='{data}'>
                            {{ data.bankName }}<br>
                            {{ data.iban }}
                        </template>
                    </Column>

                    <Column header='Giriş Tarihi' filterField='startDate' field='startDate' :sortable='false'
                            dataType='date'>
                        <template #body='slotProps'>
                            {{ formatDate(slotProps.data.startDate) }}
                        </template>
                    </Column>

                    <Column header='Çıkış Tarihi' filterField='endDate' dataType='date'>
                        <template #body='{data}'>
                            {{ formatDate(data.endDate) }}
                        </template>
                    </Column>

                    <Column header='Kayıt Tarihi' filterField='insertDate' dataType='date'>
                        <template #body='{data}'>
                            {{ formatDate(data.insertDate) }}
                        </template>
                    </Column>

                    <Column field='verified' header='Emekli Durum' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isRetired, 'text-pink-500 pi-times-circle': !data.isRetired}"></i>
                        </template>
                    </Column>

                    <Column field='verified' header='SGK Giriş' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isSgkProcessDone, 'text-pink-500 pi-times-circle': !data.isSgkProcessDone}"></i>
                        </template>
                    </Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isActive, 'text-pink-500 pi-times-circle': !data.isActive}"></i>
                        </template>
                    </Column>

                    <Column header='Düzenle' bodyClass='text-center' style='width:4rem' class="hidden">
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary' @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>

</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import EmployeeListReport from '../../components/shared/EmployeeListReport';
import { getActiveProjectId } from '@/pages/common/commonConstantFunctions';
import AdminToast from '@/components/shared/toast/adminToast.vue';

export default {
    components: { AdminToast, EmployeeListReport },
    data() {
        return {
            dataList: [],
            filters: null,
            loadingIndicator: true,
            salaryReport: {},
            employeeListReport: {
            totalPersonal: 0,
            totalPrice: 0,
            totalMenCount: 0,
            totalWomenCount: 0,
            totalOfficialPrice: 0,
            totalUnOfficialPrice: 0,
            },
        };
    },
    _firmService: null,
    _projectEmployeeService: null,
    created() {
        this.projectId = getActiveProjectId();
        this._employeeService = new EmployeeService();
        this._projectEmployeeService = new ProjectEmployeeService();
        this.initFilters();
        this.loadingIndicator = true;
    },

    async mounted() {
        await this.refreshProjectEmployeeList();
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        async exportExcel() {
            this.loadingIndicator = true;
            let excelExports = await this._projectEmployeeService.getProjectEmployeesExcel(this.projectId);
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Proje Personel Listesi ' + moment(this.paymentDate).format('DD.MM.YYYY') + '.xlsx';
            link.click();
            this.loadingIndicator = false;
        },
        async refreshProjectEmployeeList() {
            const response = await this._projectEmployeeService.getProjectEmployees(this.projectId);
            if (response.isSuccess) {
                this.dataList = response.data;
                await this.refreshProjectEmployeeReport();
            }

            this.loadingIndicator = false;
        },

        async refreshProjectEmployeeReport() {
            const response = await this._projectEmployeeService.getProjectEmployeeReports(this.projectId);
            if (response.isSuccess) {
                this.employeeListReport = response.data;
            }

            this.loadingIndicator = false;
        },
        clearFilter() {
            this.initFilters();
        },

        formatDate(value) {
            if (value == null) {
                return '-';
            }
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        edit(item) {
            this.$router.push({
                name: 'editProjectEmployee',
                params: { employeeId: item.employeeId, projectEmployeeId: item.id },
            });
        },

        
    },
};
</script>
